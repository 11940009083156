import 'shims';

import Loader from 'Loader';

import Api from 'Api/Api';
import ApiContextAuthNone from 'Api/ApiContextAuthNone';

import { LinkController } from './LinkController';
import { LinkPage } from './LinkPage';
import { ErrorPage } from './ErrorPage';

Loader.load()
  .then(siteConfig => {
    const { API_URL } = siteConfig;
    if (!API_URL) {
      throw new Error('invalid siteConfig');
    }

    if (!siteConfig.conferenceLink) {
      document.body.appendChild(<ErrorPage errorCode="ERR_API_NOT_FOUND" />);
      return;
    }

    Api.addContext(new ApiContextAuthNone('authNone', {
      API_URL,
    }));

    const ctrl = new LinkController(siteConfig.conferenceLink);

    return ctrl.init()
      .then(() => {
        document.body.appendChild(<LinkPage ctrl={ctrl} />);
      })
      .catch(err => {
        document.body.appendChild(<ErrorPage errorCode={err.code} />);
      })
      .then(() => {
        ctrl.initFinish();
      });
  })
  .then(() => Loader.loadComplete())
  .catch(err => Loader.loadError(err));
