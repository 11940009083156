import EventEmitter from 'events';

import Api from 'Api/Api';
import { ApiErrorResult } from 'Api/ApiErrors';
import ClientStorage from 'Browser/ClientStorage';

import appErrorHandler from 'appErrorHandler';

export const LINK_STATE_COLLECT_INFO = 0;
export const LINK_STATE_COLLECT_PHONE_NUMBER = 1;
export const LINK_STATE_CONNECTING = 2;
export const LINK_STATE_CONNECTED = 3;
export const LINK_STATE_INACTIVE = 4;

const POLL_TIMEOUT = 2000;

const INFO_FORM_KEY = 'cl_infoFormValues';
const NUMBER_FORM_KEY = 'cl_numberFormValues';

function loadImage(url, alt) {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.setAttribute('alt', alt || '');
    img.setAttribute('src', url);

    if (img.complete) {
      resolve(img);
      return;
    }

    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', () => resolve(null));
  });
}

function getClientStorageObject(key) {
  const ret = ClientStorage.readJSON(key);

  if (!(ret && typeof ret === 'object'))
    return {};

  return ret;
}

function getStoredInfoForm() {
  return getClientStorageObject(INFO_FORM_KEY);
}

function getStoredNumberForm() {
  return getClientStorageObject(NUMBER_FORM_KEY);
}

export class LinkController extends EventEmitter {
  constructor(conferenceLink) {
    super();

    this._conferenceLink = conferenceLink;
    this._fields = this._conferenceLink.fields || [];
    if (this._conferenceLink.isActive) {
      this._state = this._fields.length
        ? LINK_STATE_COLLECT_INFO
        : LINK_STATE_COLLECT_PHONE_NUMBER;
    } else {
      this._state = LINK_STATE_INACTIVE;
    }

    this._logoHeaderConfig = this._conferenceLink.images && this._conferenceLink.images.logoHeader;
    this._logoFooterConfig = this._conferenceLink.images && this._conferenceLink.images.logoFooter;
    this._logoHeader = null;
    this._logoFooter = null;

    this._userInfo = null;
    this._callMeErrorCode = null;
    this._callStatusToken = null;

    const storedInfoForm = getStoredInfoForm();
    this._fields.forEach(field => {
      field.initialValue = '';
      if (field.name in storedInfoForm && typeof storedInfoForm[field.name] === 'string') {
        field.initialValue = storedInfoForm[field.name];
      }
    });

    this._storedPhoneNumber = '';
    const storedNumberForm = getStoredNumberForm();
    if (storedNumberForm.phoneNumber && typeof storedNumberForm.phoneNumber === 'string')
      this._storedPhoneNumber = storedNumberForm.phoneNumber;
  }

  init() {
    this.emit('update');

    return Promise.all([
      this._logoHeaderConfig
        ? loadImage(this._logoHeaderConfig.url, this._logoHeaderConfig.alt)
        : Promise.resolve(null),
      this._logoFooterConfig
        ? loadImage(this._logoFooterConfig.url, this._logoFooterConfig.alt)
        : Promise.resolve(null),
    ])
      .then(([logoHeader, logoFooter]) => {
        this._logoHeader = logoHeader;
        this._logoFooter = logoFooter;
      });
  }

  initFinish() {
    this.emit('update');
  }

  collectInfoSubmit(userInfo) {
    this._userInfo = userInfo;

    const storedInfoForm = getStoredInfoForm();
    this._fields.forEach(field => {
      storedInfoForm[field.name] = this._userInfo[field.name];
    });
    ClientStorage.writeJSON(INFO_FORM_KEY, storedInfoForm);

    this._state = LINK_STATE_COLLECT_PHONE_NUMBER;
    this.emit('update');
  }

  callMe(phoneNumber) {
    this._callMeErrorCode = null;
    this._callStatusToken = null;

    const publicID = this._conferenceLink.publicID;

    const params = {
      publicID,
      number: phoneNumber,
      fieldData: this._userInfo,
    };

    Api.get('ConferenceLink', 'callMe', params, {}, 'authNone')
      .then(data => {
        const storedNumberForm = getStoredNumberForm();
        storedNumberForm.phoneNumber = phoneNumber;
        ClientStorage.writeJSON(NUMBER_FORM_KEY, storedNumberForm);

        this._callStatusToken = data.callMeResult.callStatusToken;
        this._state = LINK_STATE_CONNECTING;

        this._pollCallStatus();
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'number')
            this._callMeErrorCode = 'ERR_INVALID_PHONE_NUMBER';

          if (err.code === 'ERR_API_REQUEST_PARAMETER_INVALID_EXPIRED' && err.parameterName === 'publicID') {
            this._state = LINK_STATE_INACTIVE;
            return;
          }
        }
        if (!this._callMeErrorCode)
          this._callMeErrorCode = appErrorHandler(err);
      })
      .then(() => this.emit('update'));
  }

  _pollCallStatus() {
    const params = {
      callStatusToken: this._callStatusToken,
    };

    Api.get('ConferenceLink', 'getCallStatus', params, {}, 'authNone')
      .then(data => {
        switch (data.callStatus.status) {
        case 'success':
          this._state = LINK_STATE_CONNECTED;
          break;

        case 'calling':
          setTimeout(() => this._pollCallStatus(), POLL_TIMEOUT);
          break;

        default:
          this._state = LINK_STATE_COLLECT_PHONE_NUMBER;
          this._callMeErrorCode = 'ERR_CALL_STATUS_FAILED';
          break;
        }
      })
      .catch(err => {
        if (err instanceof ApiErrorResult) {
          if (err.isInvalidParamError() && err.parameterName === 'callStatusToken')
            this._callMeErrorCode = 'ERR_CALL_STATUS_FAILED';
        }
        if (!this._callMeErrorCode)
          this._callMeErrorCode = appErrorHandler(err);

        this._state = LINK_STATE_COLLECT_PHONE_NUMBER;
      })
      .then(() => this.emit('update'));
  }

  get state() {
    return this._state;
  }

  get hasStatusMessage() {
    return [
      LINK_STATE_INACTIVE,
      LINK_STATE_CONNECTING,
      LINK_STATE_CONNECTED,
    ].includes(this._state);
  }

  get linkName() {
    return this._conferenceLink.name;
  }

  get conferenceIDFormatted() {
    return this._conferenceLink.conferenceIDFormatted;
  }

  get fields() {
    return this._fields;
  }

  get logoHeader() {
    return this._logoHeader;
  }

  get logoFooter() {
    return this._logoFooter;
  }

  get callMeErrorCode() {
    return this._callMeErrorCode;
  }

  get storedPhoneNumber() {
    return this._storedPhoneNumber;
  }
}
