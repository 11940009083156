import errors from './errors';

export function ErrorPage({ errorCode }) {
  return (
    <div class="link-page">
      <div class="info-panel">
        <div class="status-message">
          <p class="mt-5">{errors[errorCode]}</p>
        </div>
      </div>
    </div>
  );
}
